/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_appsync_graphqlEndpoint": "https://35shl72zgzgdzni2oivcwjxqqu.appsync-api.ca-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "ca-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-6s2enuq55rau5lnpjvdx6r5ctm",
    "aws_cloud_logic_custom": [
        {
            "name": "appgalawebGateway",
            "endpoint": "https://9gm1hpoxu0.execute-api.ca-central-1.amazonaws.com/develop",
            "region": "ca-central-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ca-central-1:f295e323-1652-4bdc-90ef-1a8c7b3452d0",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_LPwNnyPGq",
    "aws_user_pools_web_client_id": "3m5f0h3sqhtlnpn1hjk3uaj2o4",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "appgalaweb01303946088c49e499516850beb6d199145117-develop",
    "aws_user_files_s3_bucket_region": "ca-central-1"
};


export default awsmobile;
